import { createStyles } from '@s7/styles';

export const useImageMasks = createStyles(() => ({
    ellipseMask: {
        aspectRatio: '370/300',
        maskImage: "url('/static/svg/mask-ellipse.svg')",
        maskRepeat: 'no-repeat',
        maskSize: '100%',
        WebkitMaskImage: "url('/static/svg/mask-ellipse.svg')",
        WebkitMaskPosition: '0 center',
        WebkitMaskSize: '100%',
    },

    fullCircleMask: {
        aspectRatio: '99/99',
        backgroundRepeat: 'no-repeat',
        maskImage: "url('/static/svg/mask-full-circle.svg')",
        maskPosition: 'top right',
        maskSize: '100%',
        WebkitMaskImage: "url('/static/svg/mask-full-circle.svg')",
        WebkitMaskPosition: 'center',
        WebkitMaskRepeat: 'no-repeat',
        WebkitMaskSize: '100%',
        width: '100%',
    },

    roundedRectMask: {
        aspectRatio: '360/296',
        backgroundRepeat: 'no-repeat',
        maskImage: "url('/static/svg/mask-rounded-rect.svg')",
        maskPosition: 'top right',
        maskSize: '100%',
        WebkitMaskImage: "url('/static/svg/mask-rounded-rect.svg')",
        WebkitMaskPosition: 'center',
        WebkitMaskRepeat: 'no-repeat',
        WebkitMaskSize: '100%',
        width: '100%',
    },
}));
